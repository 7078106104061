import styled              from '@emotion/styled'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import IconButton          from '@mui/material/IconButton'
import Link                from '@mui/material/Link'
import React               from 'react'

const BackToTopIconButton = styled(IconButton)`
  justify-self: end;
  color: var(--secondary);
`

const StyledLink = styled(Link)`
  color: var(--secondary);
  text-decoration: none;
`

const FooterContent = styled.footer`
  background-color: var(--bg1);
  color: var(--fg3);
  margin-top: 10vh;
  padding: 2vh 3vw 2vh 3vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  text-transform: uppercase;
  place-self: end;
  width: 100%;
`

const Footer = ({ tRef, children }) => {
  const currentYear = new Date().getFullYear()

  const scrollToRef = ref => () =>
    window.scrollTo({ top: ref.current.offsetTop, left: ref.current.offsetLeft, behavior: 'smooth' })

  return (
    <FooterContent>
      <span>
        &#169; {`2006-${currentYear} `}
        <StyledLink
          title='visit the site designers'
          aria-label='build like beavers'
          href='https://beavers.tech'
          target='_blank'
          rel='noopener'
        >
          build like beavers&#174; ltd
        </StyledLink>
      </span>
      {children}
      <BackToTopIconButton component='span' onClick={scrollToRef(tRef)} aria-label='back to top'>
        <KeyboardArrowUpIcon fontSize='large' />
      </BackToTopIconButton>
    </FooterContent>
  )
}

export { Footer }
