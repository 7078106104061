import styled          from '@emotion/styled'
import Brightness3Icon from '@mui/icons-material/Brightness3'
import WbSunnyIcon     from '@mui/icons-material/WbSunny'
import IconButton      from '@mui/material/IconButton'
import React           from 'react'
import { useTheme }    from '../hooks'

const StyledIconButton = styled(IconButton)`
 color: var(--secondary);
`

const ThemeToggle = () => {
  const [ themeState, setThemeState ] = useTheme()

  if (!themeState.hasThemeLoaded) {
    return <div />
  }

  const toggle = () => {
    const theme = themeState.theme === 'light' ? 'dark' : 'light'
    localStorage.setItem('theme', theme)
    document.body.dataset.theme = theme
    document.firstElementChild.setAttribute('color-scheme', theme)
    setThemeState({ ...themeState, theme })
  }

  return (
    <a onClick={toggle} title='toggle dark/light mode' aria-label='toggle dark/light mode'>
      <StyledIconButton aria-label='toggle dark/light mode'>
        {themeState.theme === 'dark' ? <WbSunnyIcon /> : <Brightness3Icon />}
      </StyledIconButton>
    </a>
  )
}

export { ThemeToggle }
