import React, { createContext, useContext } from 'react'
import { useEnvironment }                   from '../hooks'

const EnvironmentContext = createContext({ isProduction: false })
const useEnvironmentProvider = () => useContext(EnvironmentContext)

const EnvironmentProvider = ({ children }) => {
  const [ environmentState ] = useEnvironment()

  return (
    <EnvironmentContext.Provider value={{ isProduction: environmentState.isProduction }}>
      {children}
    </EnvironmentContext.Provider>
  )
}

export { EnvironmentProvider, useEnvironmentProvider }
