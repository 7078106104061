import styled from '@emotion/styled'
import React  from 'react'

import '../styles/not-found.css'

const SvgContainer = styled.div`
  & > svg {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  font: 14.5em/1 sans-serif;
  text-transform: uppercase;
`

const NotFoundSvg = () => (
  <svg viewBox='0 0 1320 300'>
    <defs>
      <pattern
        id='text2-pattern'
        x='0'
        y='0'
        width='20'
        height='20'
        patternUnits='userSpaceOnUse'
        patternTransform='rotate(35)'
      >
        <animateTransform
          attributeType='xml'
          attributeName='patternTransform'
          type='rotate'
          from='35'
          to='395'
          begin='0'
          dur='160s'
          repeatCount='indefinite'
        />
        <circle cx='10' cy='10' r='10' stroke='none' fill='yellow'>
          <animate
            attributeName='r'
            type='xml'
            from='1'
            to='1'
            values='1; 10; 1'
            begin='0s'
            dur='2s'
            repeatCount='indefinite'
          />
        </circle>
      </pattern>
    </defs>
    <symbol id='text0'>
      <text textAnchor='middle' x='35%' y='50%' dy='.35em'>
        4
      </text>
    </symbol>
    <symbol id='text1'>
      <text textAnchor='middle' x='50%' y='50%' dy='.35em'>
        0
      </text>
    </symbol>
    <symbol id='text2'>
      <text textAnchor='middle' x='65%' y='50%' dy='.35em'>
        4
      </text>
    </symbol>
    <use xlinkHref='#text0' className='text0' />
    <use xlinkHref='#text0' className='text0' />
    <use xlinkHref='#text0' className='text0' />
    <use xlinkHref='#text0' className='text0' />
    <use xlinkHref='#text0' className='text0' />
    <use xlinkHref='#text1' className='text1' />
    <use xlinkHref='#text1' className='text1' />
    <use xlinkHref='#text1' className='text1' />
    <use xlinkHref='#text2' className='text2' id='text2-instance' />
  </svg>
)

const NotFound = () => (
  <SvgContainer>
    <NotFoundSvg />
  </SvgContainer>
)

export { NotFound }
