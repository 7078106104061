import React, { useEffect } from 'react'
import { LoadingSpinner }   from '../components/loading-spinner'
import { setCookie }        from '../services/browser-utils'
import { useAuth0 }         from '../services/react-auth0-spa'

const AuthCheck = ({ children }) => {
  const {
    getIdTokenClaims,
    isAuthenticated,
    loading,
    loginWithRedirect,
    logout,
  } = useAuth0()

  useEffect(() => {
    if (loading || isAuthenticated) {
      return
    }
    loginWithRedirect()
  }, [ loading, isAuthenticated, loginWithRedirect ])

  useEffect(() => {
    if (isAuthenticated) {
      const getClaims = async () => {
        const claims = await getIdTokenClaims()

        if (!claims) {
          return logout({ returnTo: window.location.origin })
        }

        setCookie('x-id-token', claims.__raw, {
          path: '/',
          secure: true,
          domain: 'stuntbaboon.com',
          sameSite: 'lax',
        })
      }

      getClaims()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isAuthenticated ])

  if (loading) {
    return <LoadingSpinner />
  }

  return isAuthenticated ? children : null
}

export { AuthCheck }
