import { css }          from '@emotion/react'
import emotionNormalize from 'emotion-normalize'

import '../styles/colour-scheme.css'
import '../styles/theme.css'

const GlobalStyles = () => css`
  ${emotionNormalize}

  html,
  body,
  #___gatsby,
  #gatsby-focus-wrapper {
    height: 100%;
    width: 100%;
  }

  html {
    background-color: var(--bg1) !important;
    font-size: 100%;
    box-sizing: border-box;
  }

  body {
    background-color: var(--bg1) !important;
    font-size: 1rem;
    font-family: var(--typography-default);
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  svg:not(:root) {
    overflow: hidden;
  }

  h1 {
    margin: 0;
    padding: 0;
    font-weight: normal;
    font-size: 1.5rem;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  /********************************************************************************************/
  // video player styling
  /********************************************************************************************/

  /* Change all text and icon colors in the player. */
  .vjs-matrix.video-js {
    color: var(--video-text);
  }

  /* Change the visibility of the big play button. */
  .vjs-matrix .vjs-big-play-button {
    display: none;
  }

  /* Show controls when video hasn't started playing */
  :not(.vjs-has-started) .vjs-control-bar {
    display: flex;
  }

  .vjs-control-bar {
    background-color: var(--video-control-bar-surface) !important;
  }

  .video-js .vjs-time-control {
    display: block;
    min-width: 1em !important;
    padding-left: 0.4em !important;
    padding-right: 0.4em !important;
  }

  .video-js .vjs-remaining-time {
    display: none;
  }

  /* Change the color of various "bars". */
  .vjs-matrix .vjs-volume-level,
  .vjs-matrix .vjs-play-progress,
  .vjs-matrix .vjs-slider-bar {
    background-color: var(--video-bar);
  }

  .video-js .vjs-play-progress:before {
    color: var(--video-bar);
    font-size: 1.6rem;
  }

  .video-js .vjs-progress-control:hover .vjs-time-tooltip {
    display: none;
  }

  .video-js .vjs-progress-control:hover .vjs-progress-holder {
    font-size: inherit;
  }

  /* Tailor the custom overlays display (buttons and text) */
  .vjs-custom-overlay {
    padding: 5px;
    opacity: 0.7;
    font-size: 1rem;
    color: var(--video-text);
  }

  .video-js .vjs-overlay-top-right {
    top: initial;
    left: initial;
    right: initial;
    bottom: initial;
    top: 5px;
    right: 5px;
    z-index: 20;
    text-align: right;
  }

  .video-js .vjs-overlay-top-left {
    top: initial;
    left: initial;
    right: initial;
    bottom: initial;
    top: 5px;
    left: 5px;
    z-index: 20;
    text-align: left;
  }

  .overlay-container-fluid .vjs-overlay {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .vjs-overlay .overlay-button .icon {
    z-index: 2;
    transition-duration: 1s;
    cursor: pointer;
    text-shadow: 0 0 1px var(--video-text-shadow);
    color: var(--video-text);
    font-size: 2rem;
  }

  .vjs-overlay .previous-button .icon:hover,
  .vjs-overlay .play-button .icon:hover,
  .vjs-overlay .next-button .icon:hover {
    transform: scale(1.2);
  }

  /* Desktop overrides */
  @media only screen and (min-width: 900px) {
    .vjs-overlay .overlay-button .icon {
      font-size: 3rem;
    }

    .vjs-custom-overlay {
      font-size: 2rem;
    }

    .vjs-control-bar {
      font-size: 1.2rem;
    }
  }
`
export { GlobalStyles }
