import 'video.js/dist/video-js.min.css'
import 'videojs-landscape-fullscreen'
import 'videojs-overlay-buttons/dist/videojs-overlay-buttons.css'
import 'videojs-overlay-buttons'
import 'videojs-overlay/dist/videojs-overlay.css'
import 'videojs-overlay'
import 'videojs-playlist'
import React, { useEffect, useRef } from 'react'
import videojs                      from 'video.js'

const usePrevious = value => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const VideoPlayer = ({ playerHeight, playerOptions, playerParams, playerWidth }) => {
  const player = useRef(null)
  const playerRef = useRef(null)
  const playRateRef = useRef(null)
  const previousPlayerParams = usePrevious(playerParams)

  useEffect(() => {
    player.current = videojs(playerRef.current, playerOptions, () => { })

    player.current.on('playlistitem', (_, video) => {
      player.current.overlay({
        overlays: video.overlays,
      })
    })

    player.current.on('beforeplaylistitem', () => {
      playRateRef.current = player.current.playbackRate()
    })

    player.current.on('playlistitem', () => {
      player.current.playbackRate(playRateRef.current)
    })

    return () => player.current && player.current.dispose()
  }, [ player, playerOptions, playerRef ])

  useEffect(() => {
    const playerOverlayOptions = {
      previous: {
        handleClick: () => player.current.playlist.previous(),
      },
      seekLeft: {
        handleClick: () => player.current.currentTime(Number(player.current.currentTime()) - 10),
        doubleTap: true,
      },
      play: {
        handleClick: () => (player.current.paused() ? player.current.play() : player.current.pause()),
      },
      seekRight: {
        handleClick: () => player.current.currentTime(Number(player.current.currentTime()) + 10),
        doubleTap: true,
      },
      next: {
        handleClick: () => player.current.playlist.next(),
      },
      lockButton: false,
    }

    player.current.touchOverlay(playerOverlayOptions)
  }, [ player ])

  useEffect(() => {
    if (!previousPlayerParams || previousPlayerParams[0].key !== playerParams[0].key) {
      player.current.playlist(playerParams)
      player.current.playlist.autoadvance(0)
      player.current.playlist.first()
    }
  }, [ player, playerParams, previousPlayerParams ])

  useEffect(() => {
    player.current.landscapeFullscreen({
      fullscreen: {
        enterOnRotate: true,
        exitOnRotate: true,
        alwaysInLandscapeMode: true,
        iOS: false,
      },
    })
  }, [ player ])

  return (
    <div data-vjs-player style={{ width: playerWidth, height: playerHeight }}>
      <video ref={playerRef} className='video-js vjs-matrix vjs-default-skin vjs-big-play-centered' />
    </div>
  )
}

export { VideoPlayer }
