import React, { createContext, useContext, useState, useEffect, useRef } from 'react'

const DimensionsContext = createContext({
  dimensions: [],
})

const useDimensions = () => useContext(DimensionsContext)

const DimensionsProvider = ({ children }) => {
  const timeoutRef = useRef()
  const RATE_LIMIT_MS = 100
  const hasWindow = typeof window !== 'undefined'

  const getDimensions = () => ({
    width: window.innerWidth > window.screen.width ? window.screen.width : window.innerWidth,
    height: window.innerHeight > window.screen.height ? window.screen.height : window.innerHeight,
  })

  const [ dimensions, setDimensions ] = useState(getDimensions())

  useEffect(() => {
    if (hasWindow) {
      const handleResize = () => {
        clearTimeout(timeoutRef.current)
        timeoutRef.current = setTimeout(() => setDimensions(getDimensions()), RATE_LIMIT_MS)
      }

      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize)
    }
  }, [ hasWindow ])

  return <DimensionsContext.Provider value={{ ...dimensions }}>{children}</DimensionsContext.Provider>
}

export { DimensionsProvider, useDimensions }
