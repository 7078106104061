import React, { createContext, useContext } from 'react'

const LinksContext = createContext({
  links: null,
  navigate: null,
})

const useLinks = () => useContext(LinksContext)

const LinksProvider = ({ children, value }) => <LinksContext.Provider value={value}>{children}</LinksContext.Provider>

export { LinksProvider, useLinks }
