import { useEffect, useState } from 'react'

const useTheme = () => {
  const [ themeState, setThemeState ] = useState({ theme: 'light', hasThemeLoaded: false })

  useEffect(() => {
    const theme = localStorage.getItem('theme')
    document.body.dataset.theme = theme
    document.firstElementChild.setAttribute('color-scheme', theme)
    setThemeState({ ...themeState, theme, hasThemeLoaded: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [ themeState, setThemeState ]
}

export { useTheme }
