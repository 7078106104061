import Cookies from 'universal-cookie'

const isMobile = (width, maxMobileWidth = 900) => width <= maxMobileWidth

const setCookie = (name, value, options) => {
  const cookies = new Cookies()
  cookies.set(name, value, options)
}

export { isMobile, setCookie }
