import 'lazysizes/plugins/attrchange/ls.attrchange'
import 'lazysizes/plugins/native-loading/ls.native-loading'
import { navigate }       from 'gatsby'
import lazySizes          from 'lazysizes'
import React              from 'react'
import { GlobalWrapper }  from './src/components'
import authConfig         from './src/config/auth_config.json'
import { AuthCheck, Seo } from './src/features'
import { Auth0Provider }  from './src/services/react-auth0-spa'

lazySizes.cfg.nativeLoading = {
  setLoadingAttribute: true,
  disableListeners: {
    scroll: true,
  },
}

const addScript = url => {
  const script = document.createElement('script')
  script.src = url
  script.async = true
  document.body.appendChild(script)
}

export const onClientEntry = () => {
  window.onload = () => {
    addScript('https://player.live-video.net/1.2.0/amazon-ivs-player.min.js')
  }
}

const onRedirectCallback = () => navigate(window.location.pathname)

export const wrapPageElement = ({ element, props }) => <GlobalWrapper {...props}>{element}</GlobalWrapper>

export const wrapRootElement = ({ element }) => (
  <>
    <Seo />

    <Auth0Provider
      domain={authConfig.domain}
      client_id={authConfig.clientId}
      redirect_uri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
      cacheLocation='localstorage'
    >
      <AuthCheck>{element}</AuthCheck>
    </Auth0Provider>
  </>
)
