import { GraphQLClient } from 'graphql-request'

const endpoint = 'https://graphql.stuntbaboon.com'

const graphQLClient = new GraphQLClient(endpoint)

const getImageMetaData = ({ token, imageKey }) => {
  const query = `
  query ($imageKey: String!) {
    image (imageKey: $imageKey) {
      exifData {
        image {
          Make
          Model
          YResolution
          ExifOffset
          XResolution
          YCbCrPositioning
          ResolutionUnit
        }
        exif {
          DateTimeOriginal
          ExifImageWidth
          ExifImageHeight
          ExifVersion
          ExposureTime
          Flash
          FlashpixVersion
          SubSecTimeOriginal
          ColorSpace
          ComponentsConfiguration
          CreateDate
          SubSecTimeDigitized
          ISO
        }
      }
    }
  }`

  const variables = { imageKey }

  graphQLClient.setHeader('Authorization', token)

  return graphQLClient.request(query, variables)
}

const getStreamUrl = ({ token, channelID }) => {
  const query = `
    query ($channelID: String!) {
      stream(channelID: $channelID) {
        url
      }
    }
  `

  const variables = { channelID }

  graphQLClient.setHeader('Authorization', token)

  return graphQLClient.request(query, variables)
}

const getStreamStatus = ({ token }) => {
  const query = `
    query {
      streamStatus {
        viewerCount
        channelArn
        health
        playbackUrl
        startTime
        state
      }
    }
  `

  graphQLClient.setHeader('Authorization', token)

  return graphQLClient.request(query)
}

export { getImageMetaData, getStreamStatus, getStreamUrl }
