import React from 'react'

import '../styles/loading-spinner.css'

const LoadingSpinner = () => (
  <div className='spinnerContainer'>
    <svg className='spinner' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'>
      <circle
        className='ring-track'
        fill='transparent'
        strokeWidth='6px'
        stroke='#9c9c9c30'
        cx='50'
        cy='50'
        r='44'
      />
      <circle
        className='loader-ring'
        fill='transparent'
        strokeWidth='6px'
        stroke='#F33C64'
        strokeDashoffset='276.460'
        strokeDasharray='276.460 276.460'
        cx='50'
        cy='50'
        r='44'
      />
      <circle
        className='loader-ring-overlay'
        fill='transparent'
        strokeWidth='6px'
        stroke='#F33C64'
        strokeDashoffset='276.460'
        strokeDasharray='276.460 276.460'
        cx='50'
        cy='50'
        r='44'
      />
    </svg>
  </div>
)

export { LoadingSpinner }
