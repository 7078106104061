import styled        from '@emotion/styled'
import ChevronRight  from '@mui/icons-material/ChevronRight'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import Home          from '@mui/icons-material/Home'
import LiveTvIcon    from '@mui/icons-material/LiveTv'
import Divider       from '@mui/material/Divider'
import Drawer        from '@mui/material/Drawer'
import ListItemIcon  from '@mui/material/ListItemIcon'
import ListItemText  from '@mui/material/ListItemText'
import ListItem      from '@mui/material/ListItem'
import List          from '@mui/material/List'
import { Link }      from 'gatsby'
import PropTypes     from 'prop-types'
import React         from 'react'
import { useYears }  from '../context'
import { useAuth0 }  from '../services/react-auth0-spa'

const NavDrawer = styled(Drawer)`
  & div:nth-of-type(3) {
    background-color: var(--bg1);
    padding-bottom: 100px;
  }
`

const PrimaryListItemText = styled(ListItemText)`
  & span:first-of-type {
    color: var(--fg1);
    font-size: 1.2rem;
    font-family: inherit;
  }
`

const MenuListItemIcon = styled(ListItemIcon)`
  vertical-align: middle;
  margin-right: 5px;
  min-width: 0;
  color: var(--fg3);

  & svg {
    font-size: 2rem;
  }
`

const LivestreamListItemIcon = styled(MenuListItemIcon)`
  color: var(--fg1);
`

const LogoutListItemIcon = styled(MenuListItemIcon)`
  color: var(--secondary);
`

const MenuList = styled(List)`
  width: 300px;
`

const StyledDivider = styled(Divider)`
  background-color: var(--bg3);
`

const NavigationDrawer = ({ sidebarActive, handleToggle }) => {
  const { years } = useYears()
  const { logout } = useAuth0()

  const handleLogout = () => logout({ returnTo: window.location.origin })

  return (
    <NavDrawer open={sidebarActive} onClose={handleToggle}>
      <MenuList>
        <Link className='listItem' to='/' onClick={handleToggle} aria-label='go to home page'>
          <ListItem button disableRipple disableTouchRipple key='home'>
            <PrimaryListItemText>Home</PrimaryListItemText>
            <MenuListItemIcon>
              <Home />
            </MenuListItemIcon>
          </ListItem>
        </Link>
        <StyledDivider />
        <a onClick={handleLogout} title='log out' aria-label='log out'>
          <ListItem
            button
            disableRipple
            disableTouchRipple
            key='logout'
          >
            <PrimaryListItemText>Logout</PrimaryListItemText>
            <LogoutListItemIcon secondary='true'>
              <ExitToAppIcon />
            </LogoutListItemIcon>
          </ListItem>
        </a>
        <StyledDivider />
        <Link
          className='listItem'
          to='/app/stream'
          onClick={handleToggle}
          aria-label='go to live stream page'
          state={{ years }}
        >
          <ListItem
            button
            disableRipple
            disableTouchRipple
            key='stream'
          >
            <PrimaryListItemText>Live stream</PrimaryListItemText>
            <LivestreamListItemIcon>
              <LiveTvIcon />
            </LivestreamListItemIcon>
          </ListItem>
        </Link>
        <StyledDivider />
        {years.map(({ year }) => (
          <Link
            className='listItem'
            to={`/media/${year}`}
            key={year}
            onClick={handleToggle}
            aria-label={`go to ${year} page`}
          >
            <ListItem
              button
              disableRipple
              disableTouchRipple
              key={year}
            >
              <PrimaryListItemText>{year}</PrimaryListItemText>
              <MenuListItemIcon>
                <ChevronRight />
              </MenuListItemIcon>
            </ListItem>
          </Link>
        ))}
      </MenuList>
    </NavDrawer>
  )
}

NavigationDrawer.propTypes = {
  handleToggle: PropTypes.func,
  sidebarActive: PropTypes.bool,
}

export { NavigationDrawer }
