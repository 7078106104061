import { Fraction } from 'fractional'

const placeholderImageDimensions = {
  small: [
    {
      width: 250,
      height: 350,
    },

    {
      width: 500,
      height: 350,
    },
    {
      width: 350,
      height: 500,
    },
  ],
  medium: [
    {
      width: 350,
      height: 500,
    },
    {
      width: 800,
      height: 600,
    },
    {
      width: 600,
      height: 800,
    },
  ],
  original: [
    {
      width: 600,
      height: 800,
    },

    {
      width: 1024,
      height: 768,
    },
    {
      width: 768,
      height: 1024,
    },
  ],
}

const placeholderImage = (id = 1, size = 'small') => {
  const index = Math.floor(Math.random() * 3)
  const { width, height } = placeholderImageDimensions[size][index]
  return `https://picsum.photos/id/${id}/${width}/${height}`
}

const placeholderVideoPlaylist = (year = 2022, month = 2) => ({
  videos: [
    {
      // eslint-disable-next-line max-len
      hls: 'https://multiplatform-f.akamaihd.net/i/multi/april11/sintel/sintel-hd_,512x288_450_b,640x360_700_b,768x432_1000_b,1024x576_1400_m,.mp4.csmil/master.m3u8',
      key: `${year}${month}01`,
      month,
      seq: 1,
      year,
    },
    {
      hls: 'https://multiplatform-f.akamaihd.net/i/multi/will/bunny/big_buck_bunny_,640x360_400,640x360_700,640x360_1000,950x540_1500,.f4v.csmil/master.m3u8',
      key: `${year}${month}02`,
      month,
      seq: 2,
      year,
    },
  ],
  year,
})

const flashUsedLiteral = flashVal => ((flashVal & 1) !== 0 ? 'Yes' : 'No')

const exposureTimeFraction = exposureTime => {
  const f = new Fraction(Math.round(exposureTime * 1000) / 1000)
  return `${f.numerator}/${f.denominator}s`
}

const fetchImageWithAuthentication = url => async token => {
  const response = await fetch(url, {
    method: 'GET',
    headers: { 'x-access-token': token },
  })

  const blob = await response.blob()
  return URL.createObjectURL(blob)
}

const downloadImageViaDynamicLink = async ({ url, key }) => {
  const response = await fetch(url)
  const blob = await response.blob()
  const imageUrl = URL.createObjectURL(blob)

  const link = document.createElement('a')
  document.body.appendChild(link)
  link.download = key.substring(key.lastIndexOf('/') + 1, key.length)
  link.href = imageUrl
  link.click()
  document.body.removeChild(link)
  URL.revokeObjectURL(imageUrl)
}

const yearNavigationLinks = (index, years) => {
  const links = {
    up: {
      key: 'home',
      url: '/',
    },
    prev: {},
    next: {},
    year: years[index].year,
  }

  if (index > 0) {
    links.prev.key = index > 0 ? years[index - 1].year : null
    links.prev.url = `/media/${links.prev.key}`
  }

  if (index + 1 < years.length) {
    links.next.key = index < years.length - 1 ? years[index + 1].year : null
    links.next.url = `/media/${links.next.key}`
  }

  return links
}

const monthNavigationLinks = (index, months, year) => {
  const links = {
    up: {
      key: year,
      url: `/media/${year}`,
    },
    next: {},
    prev: {},
    year,
  }

  if (index > 0) {
    const previousMonth = months[index - 1]
    links.prev.key = index > 0 ? `${previousMonth}` : null
    links.prev.url = `/media/${year}/${links.prev.key}`
  }

  if (index + 1 < months.length) {
    const nextMonth = months[index + 1]
    links.next.key = index < months.length - 1 ? `${nextMonth}` : null
    links.next.url = `/media/${year}/${links.next.key}`
  }

  return links
}

const imageNavigationLinks = ({ imageIndex, year, month, total, images, years }) => {
  const links = {
    up: {
      key: month,
      url: `/media/${year}/${month}`,
    },
    next: {},
    prev: {},
    images,
    years,
    year,
    total,
  }

  if (imageIndex > 0) {
    links.prev.key = imageIndex - 1
    links.prev.url = `/app/image/${year}/${month}/${links.prev.key}`
  }

  if (imageIndex + 1 < total) {
    links.next.key = imageIndex + 1
    links.next.url = `/app/image/${year}/${month}/${links.next.key}`
  }

  return links
}

const playerDimensions = ({ vw, vh, maxWidth = 1280, margin = 20, ratio = 0.5625 }) => {
  const playerWidth = vw > maxWidth ? Math.floor(maxWidth - maxWidth * (margin / 100)) : Math.floor(vw - vw * (margin / 100))

  return {
    playerWidth,
    playerHeight: Math.floor(playerWidth * ratio),
  }
}

export {
  downloadImageViaDynamicLink,
  exposureTimeFraction,
  fetchImageWithAuthentication,
  flashUsedLiteral,
  imageNavigationLinks,
  monthNavigationLinks,
  placeholderImage,
  placeholderVideoPlaylist,
  playerDimensions,
  yearNavigationLinks,
}
