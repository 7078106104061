import createAuth0Client                                         from '@auth0/auth0-spa-js'
import React, { createContext, useState, useEffect, useContext } from 'react'

const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname)

export const Auth0Context = createContext()

export const useAuth0 = () => useContext(Auth0Context)

export const Auth0Provider = ({ children, onRedirectCallback = DEFAULT_REDIRECT_CALLBACK, ...initOptions }) => {
  const [ isAuthenticated, setIsAuthenticated ] = useState()
  const [ user, setUser ] = useState()
  const [ auth0Client, setAuth0 ] = useState()
  const [ loading, setLoading ] = useState(true)
  const [ popupOpen, setPopupOpen ] = useState(false)

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions)
      setAuth0(auth0FromHook)

      if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
        const { appState } = await auth0FromHook.handleRedirectCallback()
        onRedirectCallback(appState)
      }

      const authenticated = await auth0FromHook.isAuthenticated()

      setIsAuthenticated(authenticated)

      if (isAuthenticated) {
        const authenticatedUser = await auth0FromHook.getUser()
        setUser(authenticatedUser)
      }

      setLoading(false)
    }
    initAuth0()
    // eslint-disable-next-line
	}, [])

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true)
    try {
      await auth0Client.loginWithPopup(params)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    } finally {
      setPopupOpen(false)
    }

    const authenticatedUser = await auth0Client.getUser()
    setUser(authenticatedUser)
    setIsAuthenticated(true)
  }

  const handleRedirectCallback = async () => {
    setLoading(true)
    await auth0Client.handleRedirectCallback()
    const authenticatedUser = await auth0Client.getUser()

    setLoading(false)
    setIsAuthenticated(true)
    setUser(authenticatedUser)
  }

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) => auth0Client.logout(...p),
      }}
    >
      {children}
    </Auth0Context.Provider>
  )
}
