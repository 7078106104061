import { useEffect, useState } from 'react'
import { useAuth0 }            from '../services/react-auth0-spa'

const useIdToken = () => {
  const [ idToken, setIdToken ] = useState(null)
  const { getIdTokenClaims, logout } = useAuth0()

  useEffect(() => {
    const getClaims = async () => {
      const claims = await getIdTokenClaims()
      if (!claims) {
        return logout({ returnTo: window.location.origin })
      }
      setIdToken(claims.__raw)
    }

    getClaims()
  }, [ getIdTokenClaims, logout ])

  return [ idToken, setIdToken ]
}

export { useIdToken }
