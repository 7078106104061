import styled from '@emotion/styled'
import Card   from '@mui/material/Card'
import Grid   from '@mui/material/Grid'

const RealGrid = styled(Grid)`
  display: grid;
  gap: 0;
`

const PageContainerGrid = styled(RealGrid)`
  grid-template-columns: 1fr;
  grid-template-rows: ${({ rowcount }) => `repeat(${rowcount}, max-content) auto`};
  min-height: 100%;
`

PageContainerGrid.defaultProps = {
  rowcount: 2,
}

const ImageGrid = styled(RealGrid)`
  gap: 1em;
  row-gap: 2em;
  grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
  justify-items: center;
`

const ImageCard = styled(Card)`
  background-color: var(--bg1);
  border-radius: 0;
  box-shadow: var(--rad-shadow);
  height: fit-content;
  min-width: 310px;
  margin: 10px;
  position: relative;
  transition: transform 0.2s;

  & div {
    background-position: top center;
    width: 310px;
    height: 250px;
  }

  &:hover,
  &:focus-within {
    filter: none;
    transform: scale(1.05);
    box-shadow: 0px 0px 5px 5px;
  }
`

const ImageTextOverlay = styled.section`
  background: var(--bg1-50);
  bottom: 0;
  color: var(--fg1);
  line-height: ${({ lh = 2 }) => `${lh}em`};
  padding-left: 5px;
  position: absolute;
  width: 100%;
`

const StyledImage = styled.img`
  display: block;
  max-height: calc(100vh - 90px);
  max-width: 100%;
  width: 100vw;
  margin: 0;
  object-fit: contain;

  @media only screen and (min-width: 900px) {
    margin-top: 10px;
  }
`

export { ImageCard, ImageGrid, ImageTextOverlay, PageContainerGrid, RealGrid, StyledImage }
