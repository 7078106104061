import { Helmet } from 'react-helmet'
import React      from 'react'

const Head = ({ title }) => (
  <Helmet defaultTitle='Digital Content' title={title} titleTemplate='Digital Content | %s'>
    <meta property='og:title' content={`Digital Content | ${title}`} />
  </Helmet>
)

export { Head }
