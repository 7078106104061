import React, { createContext, useContext } from 'react'
import { useSlideShow }                     from '../hooks'

const SlideShowSelectorContext = createContext({ playing: false, toggle: () => { } })
const useSlideShowSelector = () => useContext(SlideShowSelectorContext)

const SlideShowSelectorProvider = ({ children }) => {
  const [ slideShowState, setSlideShowState ] = useSlideShow()

  const toggle = () => {
    const playing = !slideShowState.playing
    localStorage.setItem('playing', JSON.stringify(playing))
    setSlideShowState({ ...slideShowState, playing })
  }

  return (
    <SlideShowSelectorContext.Provider value={{ playing: slideShowState.playing, toggle }}>
      {children}
    </SlideShowSelectorContext.Provider>
  )
}

export { SlideShowSelectorProvider, useSlideShowSelector }
