import { PropTypes }        from 'prop-types'
// eslint-disable-next-line no-unused-vars
import React, { useEffect } from 'react'

const KeyBinding = props => {
  const blacklistTargets = [ 'textarea', 'input', 'select' ]

  const onKey = e => {
    if (props.preventDefault) {
      e.preventDefault()
    }

    if (props.stopPropagation) {
      e.stopPropagation()
    }

    if (!(props.preventInputConflict && blacklistTargets.indexOf(e.target.tagName.toLowerCase()) > -1)) {
      props.onKey(e)
    }
  }

  useEffect(() => {
    if (typeof props.target === 'string') {
      document.querySelector(props.target).addEventListener(props.type, onKey)
    } else if (typeof props.target === 'object') {
      props.target.addEventListener(props.type, onKey)
    }

    return function cleanUp () {
      if (typeof props.target === 'string') {
        document.querySelector(props.target).removeEventListener(props.type, onKey)
      } else if (typeof props.target === 'object') {
        props.target.removeEventListener(props.type, onKey)
      }
    }
  })

  return null
}

KeyBinding.defaultProps = {
  onKey: () => { },
  type: 'keydown',
  preventInputConflict: false,
  preventDefault: false,
  stopPropagation: false,
}

KeyBinding.propTypes = {
  onKey: PropTypes.func,
  type: PropTypes.string,
  target: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]),
  preventInputConflict: PropTypes.bool,
  preventDefault: PropTypes.bool,
  stopPropagation: PropTypes.bool,
}

export { KeyBinding }
