import { useEffect, useState } from 'react'

const useSlideShow = () => {
  const [ slideShowState, setSlideShowState ] = useState({ playing: false })

  useEffect(() => {
    const playing = localStorage.getItem('playing') === 'true'
    setSlideShowState({ ...slideShowState, playing })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [ slideShowState, setSlideShowState ]
}

export { useSlideShow }
