/* eslint-disable no-undef */
import styled                   from '@emotion/styled'
import ArrowBack                from '@mui/icons-material/ArrowBack'
import ArrowForward             from '@mui/icons-material/ArrowForward'
import ArrowUpward              from '@mui/icons-material/ArrowUpward'
import PlayArrow                from '@mui/icons-material/PlayArrow'
import Stop                     from '@mui/icons-material/Stop'
import IconButton               from '@mui/material/IconButton'
import { Link }                 from 'gatsby'
import React, { useState }      from 'react'
import { PlayingIcon }          from '../components'
import { useSlideShowSelector } from '../context'
import { useInterval }          from '../hooks'
import { KeyBinding }           from '.'

const StyledIconButton = styled(IconButton)`
  color: var(--secondary);
`

const HeaderLinks = ({ links, navigate }) => {
  const [ showStopButton, setShowStopButton ] = useState(false)
  const { playing, toggle } = useSlideShowSelector()
  const { images, years } = links

  const showStop = () => setShowStopButton(true)
  const hideStop = () => setShowStopButton(false)

  const handleKeyPress = e => {
    if (e.keyCode === 37 && links && links.prev.url) {
      navigate(links.prev.url, { state: { years, images } })
    } else if (e.keyCode === 39 && links && links.next.url) {
      navigate(links.next.url, { state: { years, images } })
    } else if (e.keyCode === 38 && links && links.up.url) {
      navigate(links.up.url)
    } else if (e.keyCode === 32 && links && links.next.url) {
      toggle()
    }
  }

  // set playing state to false if no more next links
  if (playing && links && !links.next.url) {
    toggle()
  }

  const play = () => {
    if (links && links.next && links.next.url) {
      navigate(links.next.url, { state: { years, images } })
    }
  }

  useInterval(() => play(), playing ? 5000 : null)

  return (
    <>
      {links && links.up.url && (
        <Link to={links.up.url} title='go up a level' aria-label='go up a level' tabIndex='-1'>
          <StyledIconButton aria-label='go up a level'>
            <ArrowUpward />
          </StyledIconButton>
        </Link>
      )}

      {links && links.prev.url && (
        <Link
          to={links.prev.url}
          state={{ images, years }}
          title='go to previous'
          aria-label='go to previous'
          tabIndex='-1'
        >
          <StyledIconButton aria-label='go to previous'>
            <ArrowBack />
          </StyledIconButton>
        </Link>
      )}

      {links && links.next.url && (
        <>
          <Link
            to={links.next.url}
            state={{ images, years }}
            title='go to next'
            aria-label='go to next'
            tabIndex='-1'
          >
            <StyledIconButton aria-label='go to next'>
              <ArrowForward />
            </StyledIconButton>
          </Link>
          <a onClick={toggle} title='control slideshow'>
            {playing && (
              <StyledIconButton
                onMouseEnter={showStop}
                onMouseLeave={hideStop}
                aria-label='stop slideshow'
              >
                {showStopButton && <Stop />}
                {!showStopButton && <PlayingIcon />}
              </StyledIconButton>
            )}

            {!playing && (
              <StyledIconButton onMouseLeave={hideStop} aria-label='start slideshow'>
                <PlayArrow />
              </StyledIconButton>
            )}
          </a>
        </>
      )}

      <KeyBinding onKey={handleKeyPress} target={window} />
    </>
  )
}

export { HeaderLinks }
