import { Helmet } from 'react-helmet'
import React      from 'react'
import { Head }   from '../components'

const SiteHelmet = ({ description, lang, meta, site }) => (
  <>
    <Helmet
      htmlAttributes={{ lang }}
      meta={[
        {
          name: 'description',
          content: description || site.siteMetadata.description,
        },
        {
          property: 'og:description',
          content: description || site.siteMetadata.description,
        },
        {
          property: 'og:url',
          content: site.siteMetadata.siteUrl,
        },
        {
          property: 'og:type',
          content: 'website',
        },
      ].concat(meta)}
    />
    <Head title={site.siteMetadata.title} />
  </>
)

export { SiteHelmet }
