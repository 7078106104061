import React, { useEffect, useRef } from 'react'

import stinger                      from '../assets/stream-stinger.png'

// see https://github.com/aws-samples/amazon-ivs-player-web-sample
// see https://github.com/aws-samples/amazon-ivs-mini-player-web-demo
// see https://docs.aws.amazon.com/ivs/latest/userguide/player-web.html
// see https://aws.github.io/amazon-ivs-player-docs/1.2.0/web/index.html

const IvsPlayer = ({ width, height, url }) => {
  const player = useRef(null)
  const video = useRef(null)

  const { IVSPlayer } = window

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    const { isPlayerSupported, ErrorType, PlayerEventType, PlayerState } = IVSPlayer
    const { ERROR, NETWORK_UNAVAILABLE } = PlayerEventType

    if (!isPlayerSupported) {
      // console.warn('The current browser does not support the IVS player.')
      return
    }

    const reconnectStream = delay => setTimeout(() => player.current.load(url), delay)

    const onStateChange = () => {
      const playerState = player.current.getState()

      if (playerState === PlayerState.ENDED) {
        reconnectStream(10000)
      }
    }

    const onError = _error => {
      // if (error.type === ErrorType.NOT_AVAILABLE && error.code === 429) {
      //   console.error('Concurrent-viewer limit reached', error)
      // } else {
      //   console.error('PlayerEvent - ERROR', error)
      // }

      reconnectStream(10000)
    }

    const addListeners = plyr => {
      Object.values(PlayerState).map(state => plyr.current.addEventListener(state, onStateChange))
      plyr.current.addEventListener(ERROR, onError)
      plyr.current.addEventListener(NETWORK_UNAVAILABLE, onError)
    }

    const removeListeners = plyr => {
      Object.values(PlayerState).map(state => plyr.current.removeEventListener(state, onStateChange))
      plyr.current.removeEventListener(ERROR, onError)
      plyr.current.removeEventListener(NETWORK_UNAVAILABLE, onError)
    }

    player.current = IVSPlayer.create()
    player.current.attachHTMLVideoElement(video.current)
    player.current.load(url)
    player.current.setAutoplay(true)
    addListeners(player)

    return () => {
      removeListeners(player)
      player.current.delete()
    }
  }, [ IVSPlayer, url ])

  return (
    <>
      <video id='video-player' ref={video} playsInline muted controls poster={stinger} />
    </>
  )
}

export { IvsPlayer }
