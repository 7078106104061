import { useStaticQuery, graphql } from 'gatsby'
import PropTypes                   from 'prop-types'
import React                       from 'react'
import { SiteHelmet }              from '../components'

const Seo = ({ description, lang, meta }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `,
  )

  return (
    <SiteHelmet
      lang={lang}
      meta={meta}
      site={site}
      description={description}
    />
  )
}

Seo.defaultProps = {
  lang: 'en',
  meta: [],
  description: '',
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
}

export { Seo }
