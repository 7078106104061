import { withTheme } from '@emotion/react'
import styled        from '@emotion/styled'
import Button        from '@mui/material/Button'
import React         from 'react'

const Container = styled.div`
  margin: 20px;

  & h2 {
    color: var(--fg1);
  }

  & p {
    color: var(--fg2);
    margin-top: 20px;
  }
`

const ReportButton = styled(Button)`
  color: var(--fg1);
  font-size: 1.5rem;
  margin-top: 20px;
  height: 100%;
`

class ThemedErrorBoundary extends React.Component {
  state = {
    error: null,
    errorInfo: null,
    hasError: false,
    eventId: null,
  }

  componentDidCatch (error, errorInfo) {
    this.setState({
      error,
      errorInfo,
      hasError: true,
    })
  }

  render () {
    return this.state.hasError
      ? (
        <Container>
          <h2>Something went wrong</h2>
          <div>
            <p>
              We have been notified of the error. If you also wish to
              submit a crash report, please do so by clicking the
              button below.
            </p>
            <ReportButton
              variant='contained'
              aria-label='report error'
              href={`mailto:bugs@stuntbaboon.com?subject=${this.state.error
              }&body=${JSON.stringify(this.state.errorInfo)}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              Report
            </ReportButton>
          </div>
        </Container>
      )
      : (
        this.props.children
      )
  }
}

const ErrorBoundary = withTheme(ThemedErrorBoundary)

export { ErrorBoundary }
