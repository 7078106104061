import styled      from '@emotion/styled'
import DateRange   from '@mui/icons-material/DateRange'
import Image       from '@mui/icons-material/Image'
import Button      from '@mui/material/Button'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardHeader  from '@mui/material/CardHeader'
import Card        from '@mui/material/Card'
import React       from 'react'

const ImageCardHeader = styled(CardHeader)`
  & span:first-of-type {
    color: inherit;
    font-family: inherit;
    font-size: 1.4rem;
  }
`

const ImageCard = styled(Card)`
  color: var(--fg2);
  background-color: var(--bg1);
  box-shadow: var(--rad-shadow);
`

const ImageCardActions = styled(CardActions)`
  color: var(--fg1);
  background-color: var(--bg1);
`

const ImageCardContent = styled(CardContent)`
  font-size: 1rem;
  text-align: left;

  & th {
    text-transform: uppercase;
    font-weight: bold;
  }

  & td {
    padding-left: 10px;
  }
`

const StyledButton = styled(Button)`
  background-color: var(--bg3);
  color: var(--fg1);
  font-family: var(--typography-default);

  &:hover,
  &:focus {
    background-color: var(--bg2);
  }
`

const ImageMetaData = ({
  exposureTime,
  flashUsed,
  imageDate,
  imageMetadata,
  imageTime,
  imageTitle,
  openImage,
  navigateToMonth,
}) => (
  <ImageCard>
    <ImageCardActions>
      <ImageCardHeader title={imageTitle} />
      <StyledButton
        variant='contained'
        aria-label='open image'
        onClick={openImage}
        startIcon={<Image />}
        id='open-image'
      >
        IMAGE
      </StyledButton>

      <StyledButton
        variant='contained'
        aria-label='up to month'
        onClick={navigateToMonth}
        startIcon={<DateRange />}
      >
        MONTH
      </StyledButton>
    </ImageCardActions>
    <ImageCardContent>
      <table>
        <tbody>
          <tr>
            <th>Date:</th>
            <td>{imageDate}</td>
          </tr>
          <tr>
            <th>Time:</th>
            <td>{imageTime}</td>
          </tr>
          <tr>
            <th>Camera:</th>
            <td>
              {imageMetadata.image.Make}&nbsp;
              {imageMetadata.image.Model}
            </td>
          </tr>
          <tr>
            <th>Resolution:</th>
            <td>
              {imageMetadata.exif.ExifImageWidth}&nbsp;x&nbsp;
              {imageMetadata.exif.ExifImageHeight}
            </td>
          </tr>
          <tr>
            <th>ISO:</th>
            <td>{imageMetadata.exif.ISO}</td>
          </tr>
          <tr>
            <th>Flash:</th>
            <td>{flashUsed}</td>
          </tr>
          <tr>
            <th>Exposure:</th>
            <td>{exposureTime}</td>
          </tr>
        </tbody>
      </table>
    </ImageCardContent>
  </ImageCard>
)

export { ImageMetaData }
