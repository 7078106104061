import styled                                     from '@emotion/styled'
import { parse, format }                          from 'fecha'
import React, { useEffect, useState }             from 'react'
import { ImageMetaData }                          from '../components/image-metadata'
import { useIdToken }                             from '../hooks'
import { getImageMetaData }                       from '../services/graphql-utils'
import { exposureTimeFraction, flashUsedLiteral } from '../services/media-utils'
import { useAuth0 }                               from '../services/react-auth0-spa'

const MetaDataContainer = styled.section`
  @media only screen and (min-width: 1401px) {
    margin-top: 10px;
    margin-right: 5px;
  }
`

const MetaData = ({ image: { original, meta, imageIndex, total }, navigateToMonth }) => {
  const [ imageMetadata, setImageMetadata ] = useState(null)
  const { getIdTokenClaims, getTokenSilently } = useAuth0()
  const [ idToken, setIdToken ] = useIdToken(null)

  const imageMetaDate = parse(meta.date, 'YYYY-MM-DD')
  const imageDate = format(imageMetaDate, 'dddd Do MMMM YYYY')
  const imageTime = `${meta.time.substring(0, 2)}:${meta.time.substring(2, 4)}`
  const imageTitle = `${parseInt(imageIndex, 10) + 1} ${total > 0 ? `of ${total}` : ''}`

  useEffect(() => {
    if (!idToken) {
      return
    }

    const setMetadata = async () => {
      try {
        const metadata = await getImageMetaData({ token: idToken, imageKey: original.key })
        setImageMetadata(metadata.image.exifData)
      } catch (error) {
        await getTokenSilently()
        const claims = await getIdTokenClaims()
        setIdToken(claims.__raw)
      }
    }

    setMetadata()
  }, [ getIdTokenClaims, getTokenSilently, idToken, original.key, setIdToken ])

  const openImage = e => {
    e.preventDefault()
    window.open(original.url, '_blank')
  }

  return (
    imageMetadata && (
      <MetaDataContainer>
        <ImageMetaData
          exposureTime={exposureTimeFraction(imageMetadata.exif.ExposureTime)}
          flashUsed={flashUsedLiteral(imageMetadata.exif.Flash)}
          imageDate={imageDate}
          imageMetadata={imageMetadata}
          imageTime={imageTime}
          imageTitle={imageTitle}
          openImage={openImage}
          navigateToMonth={navigateToMonth}
        />
      </MetaDataContainer>
    )
  )
}

export { MetaData }
