import styled                            from '@emotion/styled'
import MenuIcon                          from '@mui/icons-material/Menu'
import AppBar                            from '@mui/material/AppBar'
import IconButton                        from '@mui/material/IconButton'
import Toolbar                           from '@mui/material/Toolbar'
import useMediaQuery                     from '@mui/material/useMediaQuery'
import { Link }                          from 'gatsby'
import React, { useState }               from 'react'
import { ThemeToggle }                   from '../components'
import { useLinks }                      from '../context'
import { HeaderLinks, NavigationDrawer } from '../features/index'

const HeaderAppBar = styled(AppBar)`
  @media (min-width: 400px) {
    font-size: 1rem;
  }

  @media (min-width: 401px) {
    font-size: 1.6rem;
  }

  background-color: var(--bg1);
  color: var(--fg1);
`

const YearTextSpan = styled.span`
  margin-left: 10px;
  color: var(--secondary);
  font-weight: bold;
`

const NavIconButton = styled(IconButton)`
  & svg {
    width: 36px;
    height: 36px;
  }
`
const HeaderLinksContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1 1 auto;
`

const Header = () => {
  const [ sidebarActive, setSidebarActive ] = useState(false)
  const { links, navigate } = useLinks()
  const headerLinksDisplay = useMediaQuery('(min-width:680px)')

  const handleToggle = () => setSidebarActive(!sidebarActive)

  return (
    <>
      <HeaderAppBar position='sticky'>
        <Toolbar>
          <a onClick={handleToggle}>
            <NavIconButton color='inherit' onClick={handleToggle} aria-label='menu button'>
              <MenuIcon />
            </NavIconButton>
          </a>
          <span>
            <Link to='/'>{process.env.GATSBY_SITE_TITLE}</Link>
          </span>
          {links && links.year && (
            <Link to={`/media/${links.year}`} aria-label={`go to ${links.year}`}>
              <YearTextSpan>{links.year}</YearTextSpan>
            </Link>
          )}
          <HeaderLinksContainer>
            {headerLinksDisplay && links && <HeaderLinks links={links} navigate={navigate} />}
          </HeaderLinksContainer>

          <ThemeToggle />
        </Toolbar>
      </HeaderAppBar>
      <NavigationDrawer sidebarActive={sidebarActive} handleToggle={handleToggle} />
    </>
  )
}

export { Header }
